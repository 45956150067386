/* UTILITY STYLES */

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: '';
  clear: both;
  height: 0;
}

:root {
  --grey100: #fbfcfe;
  --grey200: #ecf0f9;
  --grey300: #dbe2ee;
  --grey400: #b0c0d8;
  --grey500: #869ab8;
  --grey600: #506690;
  --grey700: #2f4c75;
  --red200: #facdcd;
  --red300: #f29b9b;
  --red500: #cb2020;
  --red800: #911111;
  --black: #161c2d;
  --white: #ffffff;
  --primary: #006699;
  --primary-desat: #6c8aec;
  --teal400: #37b9da;
  --dark: #082b45;
  --success: #42ba96;
  --yellow200: #fefecc;
  --yellow300: #fbf0a7;
  --yellow400: #fbf0a7;
  --yellow500: #f0c128;
  --yellow600: #de911d;
  --light100: #ecf9fe;
  --light200: #cfedfb;
  --light300: #96d0ee;
  --b-rad: 4px;
  --px-form: 60px;
}

html {
  font-size: 68.75%; /* this is often 62.5% to make the base font size 10px but ALL the font sizes needed to be upped by 10% so this was the most consistent way to do it. */
}

body {
  font-size: 1.4rem;
  letter-spacing: 0.3px;
  line-height: 1.4;
  color: var(--black);
  position: relative;
  min-height: 99vh;
  /* We need this function in mobile browser to block pull-down refreshing function  */
  overscroll-behavior: contain;
}

h4 {
  text-transform: uppercase;
  font-weight: 500;
  margin: 0;
  font-size: 1.4rem;
}

ul {
  list-style: none;
  margin: 10px 0;
  padding: 0;
}
.mainLabel ol {
  margin: 0;
  padding: 0;
  list-style-position: inside;
}

.relationship-label {
  font-style: italic;
}

.bodyText ol {
  counter-reset: my-awesome-counter;
  list-style: none;
  margin: 0;
  padding: 0 25px;
}
.bodyText ol li {
  counter-increment: my-awesome-counter;
  position: relative;
}

.bodyText ol li::before {
  content: counter(my-awesome-counter) '. ';
  position: absolute;
  font-weight: 500;
  font-size: 1.4rem;
  left: -25px;
}
.m-0 {
  margin: 0;
}

.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.align-self-start {
  align-self: flex-start;
}

.round {
  border-radius: 50%;
}

.text-size-sm {
  font-size: 1.2rem;
}

.medicalConditions {
  margin-right: 20px;
}

.medicalCondition {
  display: inline-block;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-left: 5px;
  background-color: var(--grey200);
  color: var(--grey600);
}

.medicalCondition-0 {
  background-color: var(--teal400);
}

.medicalCondition-1 {
  background-color: var(--yellow500);
}

.medicalConditionDescription {
  padding: 10px;
}

/* GENERAL STYLES */

body {
  margin: 0px;
  background: var(--grey100);
  color: var(--black);
}

input.bp3-input,
textarea.bp3-input {
  background-color: var(--grey100);
  padding: 25px 17px;
  box-shadow: none;
  font-size: 1.8rem;
  letter-spacing: 0.5px;
  border: 1px solid var(--grey300);
  width: 100%;
  resize: none;
  font-weight: 300;
}

div.bp3-input {
  padding: 10px 15px;
}

.bp3-input-ghost {
  font-size: 1.8rem;
}

textarea.bp3-input {
  font-family: inherit;
}

label.bp3-label,
.subLabel,
.mainLabel {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.6;
}

.siblingLabel > .subLabel {
  min-width: 140px;
}

label.bp3-label,
.subLabel {
  max-width: 300px;
}

.subLabel {
  text-transform: uppercase;
  margin-bottom: 0;
  text-align: right;
}

.subLabel.noUppercase {
  text-transform: none;
}

.explainerText {
  padding: 0;
  margin: 0;
  line-height: 1;
  font-size: 1rem;
  font-weight: 400;
  color: var(--grey700);
}

ol .explainerText {
  padding-left: 20px;
}

.siblingLabel .explainerText {
  text-align: right;
}

.mainLabel {
  margin-bottom: 40px;
}

.mainNav {
  background: var(--dark);
  color: white;
  margin-bottom: 24px;
  padding: 10px;
}

.bodyText {
  font-size: 1.6rem;
  letter-spacing: 0.5px;
  font-weight: 300;
  line-height: 1.6;
}

p {
  margin-bottom: 25px;
}

/* Person Children */

.addChildren {
  display: flex;
  gap: 1.6rem;
  margin-bottom: 20px;
}

/* Person Sibling Step */

.siblingInput {
  min-width: 100px;
}

.siblingGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 40px;
}

.siblingGrid > button {
  margin-bottom: 0;
}

.siblingGrid .bp3-form-group {
  justify-content: flex-end;
  align-items: center;
}

.bp3-form-group {
  margin-bottom: 25px;
}

.nameField {
  float: left;
  width: calc(50% - 10px);
  margin: 0px 10px 15px;
}
.nameField:first-of-type {
  margin-left: 0px;
}
.nameField:last-of-type {
  margin-right: 0px;
}

/* overwrites bug in blueprintjs to allow date inputs to be full width */
span.bp3-popover-target {
  width: 100%;
}

/* LAYOUT */

#questionnaire {
  display: flex;
  margin: 0;
  width: 100vw;
  height: 100vh;
}

/* CARD STYLING */

.cardTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px 20px;
  border-left: 1px solid var(--light200);
  border-right: 1px solid var(--light200);
  border-top: 4px solid var(--primary);
  background-color: var(--light100);
}

.cardTitle-white {
  background-color: white;
  border-left: 1px solid var(--grey300);
  border-right: 1px solid var(--grey300);
  padding-bottom: 0px;
}

.intent-danger .cardTitle {
  background-color: var(--red200);
  border-left: 1px solid var(--red300);
  border-right: 1px solid var(--red300);
  border-top: 4px solid var(--red500);
}

.intent-warning .cardTitle {
  background-color: var(--yellow300);
  border-left: 1px solid var(--yellow500);
  border-right: 1px solid var(--yellow500);
  border-top: 4px solid var(--yellow600);
}

.cardTitle-left {
  border-top: 1px solid var(--grey300);
  border-left: 4px solid var(--grey300);
  border-bottom: 1px solid var(--grey300);
  padding-bottom: 20px;
  background-color: var(--grey100);
}

.person-entry .cardTitle.card-open {
  border-bottom-style: none;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.cardTitleIcon {
  padding-right: 10px;
}

.cardTitle-clickable {
  cursor: pointer;
}

.cardBody {
  padding: 30px 30px 40px;
  border: 1px solid var(--grey300);
  border-top: none;
  border-bottom-left-radius: var(--b-rad);
  border-bottom-right-radius: var(--b-rad);
}
.cardBody-inner {
  border-left-width: var(--b-rad);
  padding: 20px 30px 20px;
  margin: 0px 0px 40px;
}

.person-entry {
  margin-bottom: 25px;
  box-shadow: 0px 2px 3px var(--grey100);
}

.person-entry .cardTitle {
  padding: 20px 10px 20px;
  border-radius: var(--b-rad);
}

.person-entry .cardTitleIcon {
  width: 35px;
  height: 35px;
  padding: 0;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  border-radius: 50%;
}

.person-entry-info {
  margin: 0 5px 0 15px;
}

.person-entry-info h4 {
  text-transform: capitalize;
}
.person-entry-info .subLabel {
  color: var(--grey600);
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0.5px;
  text-align: left;
}

.bp3-button.bp3-outlined {
  background: white;
}
.bp3-button.bp3-intent-primary:not(.bp3-outlined):not(.bp3-minimal) {
  background-color: #006699;
}
.bp3-button.bp3-intent-primary:not(.bp3-outlined):not(.bp3-minimal):hover {
  background-color: #115b91;
}

.bp3-input:focus,
.bp3-input.bp3-active {
  box-shadow: 0 0 0 1px #006699, 0 0 0 3px rgba(42, 109, 124, 0.3),
    inset 0 1px 1px rgba(42, 109, 124, 0.2);
}

.bp3-control.bp3-switch .bp3-control-indicator {
  font-size: 2rem;
}
.bp3-control-indicator:focus {
  outline: rgba(76, 154, 183, 0.7) auto 1px;
  outline-offset: 1px;
}
/* questionnaire  */
.bp3-tree-node-content {
  height: initial;
  padding: 10px 20px;
}
.bp3-tree-node-content.bp3-tree-node-content-1 {
  padding-left: 35px;
}
.bp3-tree-node.bp3-tree-node-selected > .bp3-tree-node-content,
.bp3-tree-node-expanded .bp3-tree-node.bp3-tree-node-selected > .bp3-tree-node-content,
.bp3-control input:checked ~ .bp3-control-indicator {
  background-color: var(--primary);
}
.bp3-tree-node-content:hover,
.bp3-tree-node-expanded {
  background-color: var(--grey200);
}

.sidebar-removePerson {
  opacity: 1;
}

.bp3-tree-node-expanded .bp3-tree-node-content:hover {
  background-color: var(--grey300);
}

.bp3-tree-node-content.bp3-tree-node-content-1 {
  padding-left: 30px;
}

.bp3-callout {
  padding: 10px 25px 9px;
}

.bp3-callout.bp3-intent-primary {
  background-color: var(--light100);
}

.bp3-numeric-input .bp3-input-group {
  flex: 1;
  max-width: 100px;
}

.bp3-input-group .bp3-input-action:last-child {
  top: 6px;
  right: 4px;
}

.home-callout {
  max-width: 400px;
  background-color: inherit;
  margin: 20px auto 0;
  text-align: center;
}

#questionnaire-current-step {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow-y: auto;
  padding: 0;
  box-shadow: none;
}
#questionnaire-current-step > .cardTitle {
  border: none;
  padding: 30px var(--px-form);
}
#questionnaire-current-step > .cardBody {
  border: none;
  padding: 30px var(--px-form);
  max-width: 1100px;
  flex-grow: 1;
}

.infoCard,
#questionnaire-current-step {
  padding: 0px 0;
  box-shadow: none;
}

.questionnaire-nav-sidebar {
  border-right: 2px solid var(--grey500);
  padding-top: 40px;
  max-width: 320px;
  overflow-y: scroll;
  width: 24vw;
  z-index: 1;
}

.questionnaire-nav-sidebar::-webkit-scrollbar {
  display: none;
}

.questionnaire-nav-sidebar li {
  cursor: pointer;
}

#questionnaire-nav-prev-next {
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0 12px rgb(0 0 0 / 15%);
  position: sticky;
  background: white;
  width: 100%;
  padding: 20px var(--px-form);
  bottom: 0;
}

.nav-buttons {
  align-self: flex-end;
}

.nav-buttons > button {
  margin-left: 30px;
}

.save-buttons > button {
  margin-right: 10px;
}

.addButton {
  margin: 0 0 20px;
  outline: rgba(19, 124, 189, 0.6) auto 2px;
  outline-offset: 2px;
  width: 100%;
}

.cousinContainer {
  display: flex;
  align-items: flex-start;
}

.infoCardContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #082b4554;
}

.infoCard {
  min-width: 400px;
  max-width: 600px;
}

.infoCard .buttonGroup {
  margin-top: 25px;
  text-align: right;
}

.infoCard .buttonGroup .bp3-button {
  margin-right: 20px;
}

.infoCard .buttonGroup .bp3-button:last-of-type {
  margin-right: 0px;
}

.infoCard .errorDetail {
  max-height: 200px;
  overflow: scroll;
}

.errorToggle button {
  margin-bottom: 20px;
}

.bp3-non-ideal-state {
  color: var(--primary);
}

.spinner {
  margin-top: 100px;
  text-align: center;
}

.spinner-text {
  margin-top: 30px;
}

.demoModeSwitch {
  padding-top: 30px;
  color: var(--primary);
  font-size: 12px;
}

.fullNameGroup {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 15px;
}

.fullNameGroup .nameField {
  width: 100%;
  margin-left: 0;
}

div.consanguinityContainer {
  margin-top: 20px;
}

.burgerMenu {
  display: none;
}

.buttonGroupContainer {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.buttonGroupContainer .selected {
  background: rgba(19, 124, 189, 0.15);
}

.cancerDeleteButton {
  background-color: transparent;
  display: inline-block;
  margin-left: 4px;
  cursor: pointer;
  border: none;
}

.smallCrossIcon {
  vertical-align: middle;
}

.mobile-save-button {
  display: none;
  flex-grow: 1;
}

@media screen and (max-width: 1024px) {
  :root {
    --px-form: 20px;
  }

  body {
    padding-top: 0px;
  }

  #questionnaire {
    width: 100%;
  }

  .questionnaire-nav-sidebar,
  .save-buttons {
    display: none;
  }
  .mobile-save-button {
    display: initial;
  }

  #questionnaire-nav-prev-next {
    padding: 15px var(--px-form);
    justify-content: flex-end;
  }
  #questionnaire-nav-prev-next .nav-buttons > button {
    margin-left: var(--px-form);
  }

  .burgerMenu {
    display: flex;
    padding: 10px;
    justify-content: flex-end;
    position: fixed;
    top: 10px;
    right: 0;
    z-index: 2;
  }

  .burgerMenu .bp3-button.bp3-minimal.bp3-intent-primary {
    background: white;
    border: 2px solid rgba(19, 124, 189, 0.6);
  }

  .burgerMenu.opened {
    position: absolute;
    z-index: 3;
    top: 10px;
    right: 0;
  }

  .questionnaire-nav-sidebar.open {
    position: fixed;
    right: 0;
    padding-top: 80px;
    height: 100vh;
    width: 100vw;
    max-width: initial;
    display: block;
    z-index: 2;
    background: white;
    padding-right: 0;
  }

  #questionnaire-current-step {
    width: 100%;
  }

  .fullNameGroup {
    grid-template-columns: 1fr;
  }

  .siblingGrid {
    grid-template-columns: 1fr;
  }

  label.bp3-label,
  .subLabel,
  .mainLabel {
    font-size: 1.65rem;
  }

  .bp3-tree-node-label {
    font-size: 1.6rem;
  }

  .bp3-control.bp3-radio {
    font-size: 1.6rem;
  }

  #questionnaire-current-step > .cardTitle {
    box-shadow: 0 0 12px rgb(0 0 0 / 15%);
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
  }
  #questionnaire-current-step > .cardBody {
    padding-top: 100px;
    padding-bottom: 75px;
  }
  #questionnaire-nav-prev-next {
    position: fixed;
  }

  .infoCardContainer {
    z-index: 3;
  }
}

.specificCancerQuestions {
  padding: 20px;
  max-height: 500px;
  overflow-y: auto;
}

.cancerType {
  padding: 15px;
  justify-content: space-between;
  font-size: 1.5rem;
}

.cancerDialogButtons {
  display: flex;
  justify-content: space-between;
}

.bp3-form-group.dobContainer .bp3-form-content .inputs {
  display: flex;
}

.bp3-control-group.bp3-numeric-input.dobInput,
.bp3-control-group.bp3-numeric-input.measurementInput {
  display: block;
  margin: 2px;
  max-width: 92px;
}

.bp3-form-group.dobContainer .bp3-form-content .error {
  font-size: 1.2rem;
  color: red;
}

.bp3-control-group.bp3-numeric-input.dobInput input {
  text-align: center;
}

.bp3-select-popover .bp3-icon-search {
  top: 5px;
}

.bp3-select-popover input.bp3-input {
  padding: 20px 17px;
}

.modalDeletion {
  padding-right: 30px;
  padding-left: 30px;
}

.cardArrow,
.cardRemoveShow,
.cardRemoveHide {
  margin-right: 10px;
}

.cardRemoveHide {
  display: none;
}

.measurements > .measurements-header {
  display: flex;
}

.measurements > .measurements-header > .title {
  flex: 1;
}

.measurements > .measurements-header > label {
  max-width: none;
}

.measurements > .bp3-form-group.measurement-group .bp3-form-content {
  display: flex;
  align-items: center;
}

.measurements > .bp3-form-group.measurement-group .bp3-form-content > span {
  margin: 0px 25px 0px 6px;
}

.measurements > .bp3-form-group.measurement-group .measurement-input {
  display: block;
  max-width: 92px;
}

.bp3-form-content textarea {
  overflow: hidden;
}

.personCheckList {
  padding-left: 30px;
}
.personCheckList > p {
  font-weight: 500;
}
.personCheckList .bp3-checkbox {
  margin-bottom: 15px;
  font-size: 18px;
}
.personCheckList .otherQuestions {
  background: #f1f9ff;
  border-radius: 12px;
  padding: 20px 30px 5px;
  margin-left: 30px;
  margin-bottom: 20px;
}

.subLabel {
  font-size: 10px;
  font-style: italic;
}

.dropdown {
  display: inline-block;
  width: auto;
}